import { booleanState } from "../../../../components/Status";
import { formatCurrency } from "../../../../components/tools";
import { useGetAuth } from "../../../../contexts/AuthContext";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";

export const useApiHistoryDetailFormatter = () => {
  const { localTransferBanks, users } = useConstants();

  const { user } = useGetAuth();

  const getBalanceAmount = user?.partner?.disbursement_balance[0]?.balance || 0;

  const formatter = (item) => {
    const {
      sender,
      beneficiary,
      payer_id,
      amount,
      fee,
      state,
      user_id,
      callback_url,
      destination,
      // currentCountdown,
      countdownEnd,
    } = item;

    const total_amount = Number(fee) + Number(amount);

    const showDownload = state == "completed";
    const isConfirm = callback_url && state == "created";
    const isResend = callback_url && state != "created";

    const { isSubmitted, isConfirmed } = booleanState(state);
    const isInsufficient = isConfirm && getBalanceAmount < total_amount;

    const maskingState = () => {
      if (isInsufficient) return "Insufficient balance";

      if (isSubmitted || isConfirmed) return `on_progress`;
      return state;
    };

    const getName = (obj) => {
      const { registered_name, firstname, lastname } = obj || {};
      const name = registered_name || `${firstname || ""} ${lastname || ""}`;
      return name;
    };

    const getAmount = (amount) => `IDR ${formatCurrency(amount)}`;

    const status = maskingState();

    const { label: bank = "-" } =
      localTransferBanks.filter(({ value }) => value == payer_id)[0] || {};

    const account_number = beneficiary?.account || "-";

    const senderName = getName(sender);
    const beneName = getName(beneficiary);

    const currAmount = getAmount(amount);
    const currFee = getAmount(fee);
    const totalAmount = getAmount(total_amount);

    const createdByObj = users.filter(({ id }) => id == user_id)[0] || {};
    const { name, email } = createdByObj;
    const createdBy = name || email || "-";

    const isLocalTransfer =
      destination?.country_iso_code === "IDN" ||
      destination?.currency === "IDR";
    const transaction_logo = isLocalTransfer ? "local_single" : "single";

    let currentCountdown = Date.now();
    const updateTimestamp = () => (currentCountdown = Date.now());
    setInterval(updateTimestamp, 1000);

    const getSecondCurrent = Math.floor(
      (countdownEnd - currentCountdown) / 1000
    );

    return {
      ...item,
      createdBy,
      bank,
      account_number,
      senderName,
      beneName,
      currAmount,
      currFee,
      status,
      totalAmount,
      isConfirm,
      isResend,
      showDownload,
      transaction_logo,
      getSecondCurrent,
    };
  };

  return { formatter };
};
