import React, { useEffect, useState } from "react";
import { useApiHistoryDetailFormatter } from "./formatter";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { colors, jackColors } from "../../../../assets/colors";
import CustomTooltip from "../../../../components/Tooltip";
import { GothamRegular } from "../../../../components/Text";
import Countdown, { calcTimeDelta } from "react-countdown";

export const handleCountdown = (setData, id, isModal) => {
  setData((prev) => {
    const newData = prev.map((item) => {
      const currentItem = item.id === id;
      if (!currentItem) return item;
      if (isModal) {
        return {
          ...item,
          countdownEndModal: Date.now() + 30000,
        };
      }
      return {
        ...item,
        countdownEnd: Date.now() + 30000,
      };
    });
    return newData;
  });
};

const ActionButton = ({
  item,
  index,
  array,
  onAction = () => {},
  setTableData,
}) => {
  const { formatter } = useApiHistoryDetailFormatter();
  const { isConfirm, isResend, id, getSecondCurrent } = formatter(item);
  const [disabled, setDisabled] = useState(false);
  const hide = !(isResend || isConfirm);
  const { countdownEndModal } = item;

  const style = {
    backgroundColor: "transparent",
    cursor: "default",
    borderWidth: index === array.length - 1 ? 0 : 1,
    height: 65,
  };

  const name = isResend ? "ResendCallback" : "refresh";
  const isRefresh = name === "refresh";
  const resendCountdown = 30000;

  const handleClick = (type) => {
    onAction([id]);
    if (type === "refresh") {
      return null;
    }
    handleCountdown(setTableData, id);
    setDisabled(true);
  };

  const actionButtonComponent = (
    <>
      <JackIcons
        name={name}
        style={{
          height: 20,
          width: 20,
        }}
        styleDiv={{
          height: 40,
          width: 40,
          border: `1px solid ${jackColors.greyE6}`,
          borderRadius: 4,
          cursor: disabled ? "not-allowed" : "pointer",
        }}
        className="d-flex justify-content-center align-items-center hover"
        onClick={disabled ? () => {} : () => handleClick(name)}
        fill={disabled ? jackColors.neutral500 : jackColors.black34} // if disabled: neutral500
        id={`tooltip-disabled-${index}`}
      />
      {/* <Countdown date={Date.now() + resendCountdown} renderer={renderer} />
      <div onClick={cekSisa}>CEK SISA</div> */}
    </>
  );

  useEffect(() => {
    if (!disabled) return;
    const countDownDiff = countdownEndModal - Date.now();
    const countDown = !!countdownEndModal ? countDownDiff : resendCountdown;
    const disabledTimeout = setTimeout(() => {
      setDisabled(false);
      localStorage.removeItem(`resendButtonDisabled-${id}`);
    }, countDown);
    return () => clearTimeout(disabledTimeout);
  }, [disabled]);

  useEffect(() => {
    if (!countdownEndModal) return;
    setDisabled(true);
  }, [countdownEndModal]);

  if (hide)
    return (
      <tr key={index} style={style}>
        <td />
      </tr>
    );
  return (
    <tr key={index} style={style}>
      <td>
        <div className="d-flex justify-content-center align-items-center">
          {!isRefresh && disabled ? (
            <CustomTooltip
              customId={`tooltip-disabled-${index}`}
              innerClassName="editorTooltip"
              popperClassName="white-arrow"
              placement="left"
              text={
                <div
                  style={{
                    width: 280,
                    height: "auto",
                    backgroundColor: "rgba(255,255,255,1) !important",
                    opacity: "1 !important",
                    zIndex: 200,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    textAlign: "left",
                    padding: 10,
                  }}
                >
                  <GothamRegular
                    className="font12"
                    style={{ color: colors.neutral800, lineHeight: "16px" }}
                  >
                    You have just resent this transaction; please wait 30 second
                    to resend.
                  </GothamRegular>
                </div>
              }
            >
              {actionButtonComponent}
            </CustomTooltip>
          ) : (
            <CustomTooltip
              customId={`tooltip-disabled-${index}`}
              innerClassName="editorTooltip"
              popperClassName="white-arrow"
              placement="top"
              text={
                <div
                  style={{
                    width: "fit-content",
                    height: "auto",
                    backgroundColor: "rgba(255,255,255,1) !important",
                    opacity: "1 !important",
                    zIndex: 200,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    textAlign: "left",
                    padding: 0,
                  }}
                >
                  <GothamRegular
                    className="font12"
                    style={{ color: colors.neutral800, lineHeight: "16px" }}
                  >
                    {isRefresh ? "Submit Transaction" : "Resend Callback"}
                  </GothamRegular>
                </div>
              }
            >
              {actionButtonComponent}
            </CustomTooltip>
          )}
        </div>
      </td>
    </tr>
  );
};

export default ActionButton;
