import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useState } from "react";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import { useIdsTable } from "../../../components/Checkbox";
import { useGetAuth } from "../../../contexts/AuthContext";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { apiBusiness } from "../../../tools/api";
import { JackIcons } from "../../../assets/jackIcons/parent";
import apiSubmitImage from "../../../assets/images/api-submit-img.svg";
import { customSum } from "../../../components/tools";

export const useConfirmApiHistory = (afterSuccess) => {
  const [loading, setLoading] = useState(false);
  const { errorToasterApi, successSnackBar } = ToasterHook();

  const mutation = async (ids) => {
    try {
      setLoading(true);
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        await apiBusiness.post(`/disbursements/${id}/confirm`);
      }
      successSnackBar({ msg: "Transaction(s) have been submitted!" });
      afterSuccess && afterSuccess(ids);
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  return { mutation, loading };
};

export const useResendCallbackApiHistory = (afterSuccess) => {
  const [loading, setLoading] = useState(false);
  const { errorToasterApi, successSnackBar } = ToasterHook();
  let arr = [];

  const mutation = async (ids) => {
    try {
      setLoading(true);
      for (let i = 0; i < ids.length; i++) {
        const id = ids[i];
        const { data } = await apiBusiness.get(
          `/disbursement/${id}/resend_callback`
        );
        arr.push(data?.data);
      }
      successSnackBar({ msg: "Callback has been sent!" });
      afterSuccess && afterSuccess(ids, arr);
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  return { mutation, loading };
};

export const useOnActionApiHistories = ({
  array,
  setData,
  setListener = () => {},
  refetch,
  setDisabled = () => {},
}) => {
  // useful in table
  const selectTableProps = useIdsTable(array);

  const { handleRemoveAllId } = selectTableProps;

  const { isActiveAction } = useApiHistoriesActions();
  const { push } = useRouter();

  const toggleRunQuery = () => {
    push({ query: { menu: "api_transaction", runQuery: false } });
    push({ query: { menu: "api_transaction", runQuery: true } });
  };

  const reset = (ids, isConfirm = false) => {
    handleRemoveAllId();
    isActiveAction && toggleRunQuery();
    isConfirm && setListener(true);
    refetch && refetch();
    if (!isConfirm) {
      localStorage.setItem(`resendButtonDisabled-${ids[0]}`, true);
      setDisabled(true);
    }
  };

  const { user } = useGetAuth();

  const getBalanceAmount = user?.partner?.disbursement_balance[0]?.balance || 0;

  const afterSuccessConfirm = (ids) => {
    setData &&
      setData((prev) => {
        const { array: arrayRaw } = prev;
        const array = arrayRaw.map((item) => {
          const { id } = item;
          if (ids.includes(id)) return { ...item, state: "confirmed" };
          return item;
        });
        return { ...prev, array };
      });
    reset(ids, true);
  };

  const afterSuccessResend = (ids, arr) => {
    setDisabled(true);
    setData &&
      setData((prev) => {
        const { array: arrayRaw } = prev;
        const array = arrayRaw.map((item) => {
          const { id } = item;
          const getIndex = ids.indexOf(id);
          const foundIndex = getIndex !== -1;
          if (foundIndex) return { ...item, ...arr[getIndex] };
          return item;
        });
        return { ...prev, array };
      });
    reset(ids);
  };

  const { mutation: confirm, loading: loadingConfirm } =
    useConfirmApiHistory(afterSuccessConfirm);

  const { mutation: resend, loading: loadingResend } =
    useResendCallbackApiHistory(afterSuccessResend);

  const loadingAction = loadingConfirm || loadingResend;

  const handleAction = (ids) => {
    const checker = (stateProps) => {
      return ids.filter((id) => {
        const obj = array.filter(({ id: arrayId }) => arrayId == id)[0] || {};
        const { state, callback_url } = obj;
        return callback_url && state == stateProps;
      }).length;
    };

    const isConfirm = checker("created");

    if (isConfirm) return setIds(ids);

    resend(ids);
  };

  const [ids, setIds] = useState(false);

  const totalAmounts = (ids || [])
    .filter((id) => {
      const obj = array.find(({ id: childId }) => id == childId) || {};
      const { callback_url, state } = obj;
      const isConfirm = callback_url && state == "created";
      return isConfirm;
    })
    .map((id) => {
      const { amount, fee } =
        array.find(({ id: childId }) => id == childId) || {};
      return Number(amount) + Number(fee);
    });

  const totalConfirm = totalAmounts.length ? customSum(totalAmounts) : 0;

  const isInsufficient = totalConfirm && getBalanceAmount < totalConfirm;

  const confirmationModalComponent = (
    <ConfirmationModalJack
      modal={ids.length}
      title={isInsufficient ? "Are you sure to submit?" : "Confirmation"}
      text={
        isInsufficient
          ? "The submitted transaction(s) will be processed once your account has enough balance."
          : "Are you sure you want to submit this transaction(s)?"
      }
      loading={loadingAction}
      toggle={() => setIds(false)}
      onClick={() => confirm(ids)}
      isCentered
      img={apiSubmitImage}
      // image={
      //   <JackIcons
      //     name="alert-triangle"
      //     fill="black"
      //     style={{ width: 90, height: 90, marginBottom: 32 }}
      //   />
      // }
    />
  );
  // useful in table

  return {
    loadingAction,
    resend,
    confirm,
    confirmationModalComponent,
    selectTableProps,
    handleAction,
  };
};

export const useApiHistoriesActions = () => {
  const { query } = useRouter();

  const actions = [
    {
      icon: "refresh",
      label: "Submit Transactions",
      value: "confirm_transactions",
    },
    {
      label: "Resend Callback",
      icon: "resendCallback",
      value: "resend_callback",
    },
  ];

  const activeActionObj =
    actions.filter(({ value }) => {
      const keys = Object.keys(query);
      return keys.includes(value);
    })[0] || {};

  const isActiveAction = !isEmpty(activeActionObj);

  const isResend = activeActionObj.value == "resend_callback";

  return { isActiveAction, activeActionObj, actions, isResend };
};
